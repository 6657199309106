exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-overlord-js": () => import("./../../../src/pages/overlord.js" /* webpackChunkName: "component---src-pages-overlord-js" */),
  "component---src-pages-rodeoroundup-js": () => import("./../../../src/pages/rodeoroundup.js" /* webpackChunkName: "component---src-pages-rodeoroundup-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-tags-posts-js": () => import("./../../../src/templates/tags-posts.js" /* webpackChunkName: "component---src-templates-tags-posts-js" */)
}

